    .elementor-9 .elementor-element.elementor-element-60d07609>.elementor-container {
        min-height: 100vh;
    }

    .elementor-9 .elementor-element.elementor-element-60d07609:not(.elementor-motion-effects-element-type-background),
    .elementor-9 .elementor-element.elementor-element-60d07609>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-color: #000000;
        /* background-image: url("https://cozystay.loftocean.com/countryside-lodge/wp-content/uploads/sites/5/2023/03/img-50.jpg"); */
        background-position: center center;
        background-size: cover;
    }

    .elementor-9 .elementor-element.elementor-element-60d07609>.elementor-background-overlay {
        background-color: #000000;
        opacity: 0.55;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    }

    .elementor-9 .elementor-element.elementor-element-60d07609 {
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    }

    .elementor-bc-flex-widget .elementor-9 .elementor-element.elementor-element-105f5244.elementor-column .elementor-column-wrap {
        align-items: center;
    }

    .elementor-9 .elementor-element.elementor-element-105f5244.elementor-column.elementor-element[data-element_type="column"]>.elementor-column-wrap.elementor-element-populated>.elementor-widget-wrap {
        align-content: center;
        align-items: center;
    }

    .elementor-9 .elementor-element.elementor-element-105f5244>.elementor-element-populated {
        margin: 100px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-9 .elementor-element.elementor-element-105f5244>.elementor-element-populated>.elementor-widget-wrap {
        padding: 0px 70px 0px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-4753721a .cs-subtitle {
        font-size: 16px;
    }

    .elementor-9 .elementor-element.elementor-element-4753721a .cs-title {
        font-size: 60px;
    }

    .elementor-9 .elementor-element.elementor-element-4753721a .cs-title-text {
        font-size: 21px;
    }

    .elementor-9 .elementor-element.elementor-element-79386528:not(.elementor-motion-effects-element-type-background)>.elementor-column-wrap,
    .elementor-9 .elementor-element.elementor-element-79386528>.elementor-column-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-color: #2C3529DE;
    }

    .elementor-9 .elementor-element.elementor-element-79386528>.elementor-element-populated>.elementor-background-overlay {
        opacity: 0.5;
    }

    .elementor-9 .elementor-element.elementor-element-79386528>.elementor-element-populated {
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        margin: 100px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-9 .elementor-element.elementor-element-79386528>.elementor-element-populated>.elementor-background-overlay {
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    }

    .elementor-9 .elementor-element.elementor-element-79386528>.elementor-element-populated>.elementor-widget-wrap {
        padding: 10px 10px 10px 10px;
    }

    .elementor-9 .elementor-element.elementor-element-3721f659 {
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #B99D75;
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        padding: 10% 10% 10% 10%;
    }

    .elementor-9 .elementor-element.elementor-element-3721f659>.elementor-background-overlay {
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    }

    .elementor-9 .elementor-element.elementor-element-44d1fab1 .cs-subtitle-wrap {
        margin: 0px 0px -10px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-44d1fab1 .cs-title {
        text-transform: uppercase;
    }

    .elementor-9 .elementor-element.elementor-element-2877e5e8 .cs-reservation-form {
        --form-bd: #B99D75;
        --field-space: 15px;
    }

    .elementor-9 .elementor-element.elementor-element-5594a8db {
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        padding: 110px 0px 40px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-5594a8db>.elementor-background-overlay {
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    }

    .elementor-9 .elementor-element.elementor-element-523c3ab0 .cs-title-wrap {
        max-width: 900px;
    }

    .elementor-9 .elementor-element.elementor-element-bec869d>.elementor-element-populated>.elementor-widget-wrap {
        padding: 50px 0px 0px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-c247c3d {
        text-align: left;
    }

    .elementor-9 .elementor-element.elementor-element-c247c3d img {
        width: 420px;
    }

    .elementor-9 .elementor-element.elementor-element-60f2283 .list-content {
        font-size: 15px;
    }

    .elementor-9 .elementor-element.elementor-element-60f2283 .cs-list .list-icon {
        margin-right: 8px;
    }

    .elementor-9 .elementor-element.elementor-element-60f2283 .list-icon {
        font-size: 6px;
    }

    .elementor-9 .elementor-element.elementor-element-60f2283 .cs-list li {
        align-items: middle;
    }

    .elementor-9 .elementor-element.elementor-element-60f2283>.elementor-widget-container {
        margin: -8px 0px 0px 0px;
        background-color: #FFFFFF;
    }

    .elementor-9 .elementor-element.elementor-element-3348432 {
        text-align: right;
    }

    .elementor-9 .elementor-element.elementor-element-3348432 img {
        width: 410px;
    }

    .elementor-9 .elementor-element.elementor-element-3348432>.elementor-widget-container {
        margin: 90px 0px 0px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-bfe63dd .list-content {
        font-size: 15px;
    }

    .elementor-9 .elementor-element.elementor-element-bfe63dd .cs-list .list-icon {
        margin-right: 8px;
    }

    .elementor-9 .elementor-element.elementor-element-bfe63dd .list-icon {
        font-size: 6px;
    }

    .elementor-9 .elementor-element.elementor-element-bfe63dd .cs-list li {
        align-items: middle;
    }

    .elementor-9 .elementor-element.elementor-element-bfe63dd>.elementor-widget-container {
        margin: -8px 0px 0px 0px;
        background-color: #FFFFFF;
    }

    .elementor-9 .elementor-element.elementor-element-2835994.elementor-column>.elementor-column-wrap>.elementor-widget-wrap {
        justify-content: center;
    }

    .elementor-9 .elementor-element.elementor-element-2835994 {
        z-index: 9;
    }

    .elementor-9 .elementor-element.elementor-element-98bb1e1 {
        --spacer-size: 460px;
        width: var(--container-widget-width, 1px);
        max-width: 1px;
        --container-widget-width: 1px;
        --container-widget-flex-grow: 0;
    }

    .elementor-9 .elementor-element.elementor-element-98bb1e1>.elementor-widget-container {
        background-color: #B99D75;
    }

    .elementor-9 .elementor-element.elementor-element-4dd521f>.elementor-widget-container {
        margin: 20px -170px 0px -170px;
        padding: 50px 50px 50px 50px;
    }

    .elementor-9 .elementor-element.elementor-element-bdbd6ed {
        --spacer-size: 800px;
        width: var(--container-widget-width, 1px);
        max-width: 1px;
        --container-widget-width: 1px;
        --container-widget-flex-grow: 0;
    }

    .elementor-9 .elementor-element.elementor-element-bdbd6ed>.elementor-widget-container {
        background-color: #B99D75;
    }

    .elementor-9 .elementor-element.elementor-element-272e13a>.elementor-element-populated>.elementor-widget-wrap {
        padding: 280px 0px 0px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-d748e29 {
        text-align: right;
    }

    .elementor-9 .elementor-element.elementor-element-d748e29 img {
        width: 420px;
    }

    .elementor-9 .elementor-element.elementor-element-28b16fb .list-content {
        font-size: 15px;
    }

    .elementor-9 .elementor-element.elementor-element-28b16fb .cs-list .list-icon {
        margin-right: 8px;
    }

    .elementor-9 .elementor-element.elementor-element-28b16fb .list-icon {
        font-size: 6px;
    }

    .elementor-9 .elementor-element.elementor-element-28b16fb .cs-list li {
        align-items: middle;
    }

    .elementor-9 .elementor-element.elementor-element-28b16fb>.elementor-widget-container {
        margin: -8px 0px 0px 0px;
        background-color: #FFFFFF;
    }

    .elementor-9 .elementor-element.elementor-element-e0485c3 {
        text-align: left;
    }

    .elementor-9 .elementor-element.elementor-element-e0485c3 img {
        width: 600px;
    }

    .elementor-9 .elementor-element.elementor-element-e0485c3>.elementor-widget-container {
        margin: 90px 0px 0px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-904fe6b .list-content {
        font-size: 15px;
    }

    .elementor-9 .elementor-element.elementor-element-904fe6b .cs-list .list-icon {
        margin-right: 8px;
    }

    .elementor-9 .elementor-element.elementor-element-904fe6b .list-icon {
        font-size: 6px;
    }

    .elementor-9 .elementor-element.elementor-element-904fe6b .cs-list li {
        align-items: middle;
    }

    .elementor-9 .elementor-element.elementor-element-904fe6b>.elementor-widget-container {
        margin: -8px 0px 0px 0px;
        background-color: #FFFFFF;
    }

    .elementor-9 .elementor-element.elementor-element-bf15ee2 {
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .elementor-9 .elementor-element.elementor-element-5dac1486 .button {
        --btn-size: 180px;
        font-size: 18px;
    }

    .elementor-9 .elementor-element.elementor-element-67e0ef64>.elementor-container {
        min-height: 80vh;
    }

    .elementor-9 .elementor-element.elementor-element-67e0ef64:not(.elementor-motion-effects-element-type-background),
    .elementor-9 .elementor-element.elementor-element-67e0ef64>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-image: url("https://cozystay.loftocean.com/countryside-lodge/wp-content/uploads/sites/5/2023/03/david-ress-0W3oxKVx5WY-unsplash.jpg");
        background-position: center center;
        background-size: cover;
    }

    .elementor-9 .elementor-element.elementor-element-67e0ef64 {
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        margin-top: -100px;
        margin-bottom: 0px;
        z-index: 8;
    }

    .elementor-9 .elementor-element.elementor-element-67e0ef64>.elementor-background-overlay {
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    }

    .elementor-9 .elementor-element.elementor-element-41a3623d {
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        padding: 110px 0px 100px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-41a3623d>.elementor-background-overlay {
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    }

    .elementor-bc-flex-widget .elementor-9 .elementor-element.elementor-element-7b2153b6.elementor-column .elementor-column-wrap {
        align-items: center;
    }

    .elementor-9 .elementor-element.elementor-element-7b2153b6.elementor-column.elementor-element[data-element_type="column"]>.elementor-column-wrap.elementor-element-populated>.elementor-widget-wrap {
        align-content: center;
        align-items: center;
    }

    .elementor-bc-flex-widget .elementor-9 .elementor-element.elementor-element-4e125f75.elementor-column .elementor-column-wrap {
        align-items: center;
    }

    .elementor-9 .elementor-element.elementor-element-4e125f75.elementor-column.elementor-element[data-element_type="column"]>.elementor-column-wrap.elementor-element-populated>.elementor-widget-wrap {
        align-content: center;
        align-items: center;
    }

    .elementor-9 .elementor-element.elementor-element-4e125f75>.elementor-element-populated>.elementor-widget-wrap {
        padding: 0px 0px 0px 70px;
    }

    .elementor-9 .elementor-element.elementor-element-992376d .cs-title {
        font-size: 56px;
        line-height: 1.2em;
    }

    .elementor-9 .elementor-element.elementor-element-5bc85ebf {
        padding: 0px 0px 110px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-5811d0f1>.elementor-element-populated>.elementor-widget-wrap {
        padding: 0px 40px 0px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-2d2e0671>.elementor-element-populated>.elementor-widget-wrap {
        padding: 0px 20px 0px 20px;
    }

    .elementor-9 .elementor-element.elementor-element-3b3eb7f7>.elementor-element-populated>.elementor-widget-wrap {
        padding: 0px 0px 0px 40px;
    }

    .elementor-9 .elementor-element.elementor-element-799a1d60:not(.elementor-motion-effects-element-type-background),
    .elementor-9 .elementor-element.elementor-element-799a1d60>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-color: #475746;
        background-image: url("https://cozystay.loftocean.com/countryside-lodge/wp-content/uploads/sites/5/2023/05/bg-pattern-3.jpg");
        background-position: center center;
        background-repeat: repeat;
        background-size: 270px auto;
    }

    .elementor-9 .elementor-element.elementor-element-799a1d60 {
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        padding: 110px 0px 36px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-799a1d60>.elementor-background-overlay {
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    }

    .elementor-9 .elementor-element.elementor-element-2d62d31e .cs-title-wrap {
        max-width: 720px;
    }

    .elementor-9 .elementor-element.elementor-element-2d62d31e .cs-title {
        line-height: 1.2em;
    }

    .elementor-9 .elementor-element.elementor-element-2d62d31e>.elementor-widget-container {
        margin: 0px 0px 80px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-fa3855e {
        margin-top: -330px;
        margin-bottom: 0px;
    }

    .elementor-9 .elementor-element.elementor-element-73a26f7>.elementor-widget-container {
        margin: 30px 0px 0px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-7d1e1cca {
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        padding: 110px 0px 0px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-7d1e1cca>.elementor-background-overlay {
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    }

    .elementor-9 .elementor-element.elementor-element-776ae962>.elementor-element-populated>.elementor-widget-wrap {
        padding: 0px 40px 0px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-561d44c7 .cs-title {
        line-height: 1.2em;
    }

    .elementor-9 .elementor-element.elementor-element-561d44c7>.elementor-widget-container {
        margin: 0px 0px 20px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-65e68fb4 .cs-info-box {
        --box-gap: 40px;
        --icon-space: 18px;
    }

    .elementor-9 .elementor-element.elementor-element-65e68fb4 .cs-info-box-img i {
        color: #B99D75;
    }

    .elementor-9 .elementor-element.elementor-element-65e68fb4 .cs-info-box-title {
        font-size: 18px;
    }

    .elementor-9 .elementor-element.elementor-element-7cbcf1b6>.elementor-background-overlay {
        opacity: 0.5;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    }

    .elementor-9 .elementor-element.elementor-element-7cbcf1b6 {
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        padding: 110px 0px 110px 0px;
    }

    .elementor-9 .elementor-element.elementor-element-27c5fa0c.elementor-column>.elementor-column-wrap>.elementor-widget-wrap {
        justify-content: center;
    }

    .elementor-9 .elementor-element.elementor-element-5412a0ca>.elementor-widget-container {
        margin: 0px 0px 30px 0px;
    }

    @media(min-width:768px) {
        .elementor-9 .elementor-element.elementor-element-bec869d {
            width: 44%;
        }

        .elementor-9 .elementor-element.elementor-element-2835994 {
            width: 12%;
        }

        .elementor-9 .elementor-element.elementor-element-272e13a {
            width: 43.333%;
        }
    }

    @media(max-width:1024px) {
        .elementor-9 .elementor-element.elementor-element-105f5244>.elementor-element-populated>.elementor-widget-wrap {
            padding: 0px 0px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-4753721a .cs-title {
            font-size: 48px;
        }

        .elementor-9 .elementor-element.elementor-element-4753721a .cs-title-text {
            font-size: 18px;
        }

        .elementor-9 .elementor-element.elementor-element-4753721a>.elementor-widget-container {
            padding: 80px 0px 80px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-79386528>.elementor-element-populated {
            margin: 0px 0px 80px 0px;
            --e-column-margin-right: 0px;
            --e-column-margin-left: 0px;
        }

        .elementor-9 .elementor-element.elementor-element-5594a8db {
            padding: 80px 0px 40px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-bec869d>.elementor-element-populated>.elementor-widget-wrap {
            padding: 50px 40px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-bec869d {
            z-index: 2;
        }

        .elementor-9 .elementor-element.elementor-element-3348432>.elementor-widget-container {
            margin: 40px 0px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-2835994 {
            order: -1;
            z-index: 1;
        }

        .elementor-9 .elementor-element.elementor-element-2835994>.elementor-element-populated {
            margin: 0px 0px -1360px 0px;
            --e-column-margin-right: 0px;
            --e-column-margin-left: 0px;
        }

        .elementor-9 .elementor-element.elementor-element-98bb1e1 {
            --spacer-size: 40px;
        }

        .elementor-9 .elementor-element.elementor-element-4dd521f .cs-title-wrap {
            max-width: 60%;
        }

        .elementor-9 .elementor-element.elementor-element-4dd521f>.elementor-widget-container {
            margin: 0px 0px 0px 0px;
            padding: 20px 0px 12px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-bdbd6ed {
            --spacer-size: 1360px;
        }

        .elementor-9 .elementor-element.elementor-element-272e13a>.elementor-element-populated>.elementor-widget-wrap {
            padding: 100px 0px 0px 40px;
        }

        .elementor-9 .elementor-element.elementor-element-272e13a {
            z-index: 2;
        }

        .elementor-9 .elementor-element.elementor-element-e0485c3>.elementor-widget-container {
            margin: 40px 0px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-41a3623d {
            padding: 80px 0px 80px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-4e125f75>.elementor-element-populated>.elementor-widget-wrap {
            padding: 40px 0px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-992376d .cs-title {
            font-size: 36px;
        }

        .elementor-9 .elementor-element.elementor-element-5bc85ebf {
            padding: 0px 0px 80px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-5811d0f1>.elementor-element-populated>.elementor-widget-wrap {
            padding: 0px 20px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-2d2e0671>.elementor-element-populated>.elementor-widget-wrap {
            padding: 0px 10px 0px 10px;
        }

        .elementor-9 .elementor-element.elementor-element-3b3eb7f7>.elementor-element-populated>.elementor-widget-wrap {
            padding: 0px 0px 0px 20px;
        }

        .elementor-9 .elementor-element.elementor-element-799a1d60 {
            padding: 80px 0px 36px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-7d1e1cca {
            padding: 80px 0px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-776ae962>.elementor-element-populated>.elementor-widget-wrap {
            padding: 0px 0px 80px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-7cbcf1b6 {
            padding: 80px 0px 80px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-5412a0ca>.elementor-widget-container {
            margin: 0px 0px 20px 0px;
        }
    }

    @media(max-width:767px) {
        .elementor-9 .elementor-element.elementor-element-4753721a .cs-title {
            font-size: 30px;
        }

        .elementor-9 .elementor-element.elementor-element-bec869d>.elementor-element-populated>.elementor-widget-wrap {
            padding: 0px 0px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-bec869d {
            z-index: 9;
        }

        .elementor-9 .elementor-element.elementor-element-c247c3d {
            text-align: center;
        }

        .elementor-9 .elementor-element.elementor-element-3348432 {
            text-align: center;
        }

        .elementor-9 .elementor-element.elementor-element-3348432>.elementor-widget-container {
            margin: 30px 0px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-2835994>.elementor-element-populated {
            margin: 0px 0px -2200px 0px;
            --e-column-margin-right: 0px;
            --e-column-margin-left: 0px;
        }

        .elementor-9 .elementor-element.elementor-element-2835994 {
            z-index: 1;
        }

        .elementor-9 .elementor-element.elementor-element-4dd521f .cs-title-wrap {
            max-width: 100%;
        }

        .elementor-9 .elementor-element.elementor-element-bdbd6ed {
            --spacer-size: 2200px;
        }

        .elementor-9 .elementor-element.elementor-element-272e13a>.elementor-element-populated>.elementor-widget-wrap {
            padding: 0px 0px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-272e13a {
            z-index: 9;
        }

        .elementor-9 .elementor-element.elementor-element-d748e29 {
            text-align: center;
        }

        .elementor-9 .elementor-element.elementor-element-d748e29>.elementor-widget-container {
            margin: 50px 0px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-e0485c3 {
            text-align: center;
        }

        .elementor-9 .elementor-element.elementor-element-e0485c3>.elementor-widget-container {
            margin: 30px 0px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-bf15ee2 {
            padding: 30px 0px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-992376d .cs-title {
            font-size: 32px;
        }

        .elementor-9 .elementor-element.elementor-element-5811d0f1>.elementor-element-populated>.elementor-widget-wrap {
            padding: 0px 0px 40px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-2d2e0671>.elementor-element-populated>.elementor-widget-wrap {
            padding: 0px 0px 40px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-3b3eb7f7>.elementor-element-populated>.elementor-widget-wrap {
            padding: 0px 0px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-799a1d60 {
            padding: 80px 0px 0px 0px;
        }

        .elementor-9 .elementor-element.elementor-element-fa3855e {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }

    @media(max-width:1024px) and (min-width:768px) {
        .elementor-9 .elementor-element.elementor-element-105f5244 {
            width: 100%;
        }

        .elementor-9 .elementor-element.elementor-element-79386528 {
            width: 100%;
        }

        .elementor-9 .elementor-element.elementor-element-bec869d {
            width: 50%;
        }

        .elementor-9 .elementor-element.elementor-element-2835994 {
            width: 100%;
        }

        .elementor-9 .elementor-element.elementor-element-272e13a {
            width: 50%;
        }

        .elementor-9 .elementor-element.elementor-element-7b2153b6 {
            width: 100%;
        }

        .elementor-9 .elementor-element.elementor-element-4e125f75 {
            width: 100%;
        }

        .elementor-9 .elementor-element.elementor-element-776ae962 {
            width: 100%;
        }

        .elementor-9 .elementor-element.elementor-element-3ad70fae {
            width: 100%;
        }
    }